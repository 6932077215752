<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="FundraisingController"
        action="save"
        add-route-name="fundraising-add"
        text="Add Fundraising Project"
      />
    </div>

    <!-- Search -->
    <b-card header="Search">
      <b-form-row>
        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <search
            placeholder="Title"
            :search-query.sync="searchQuery.title"
            @refresh="refreshTable"
          />
        </b-col>

        <b-col md="6">
          <date-range-filter
            :date-range.sync="searchQuery.range"
          />
        </b-col>

        <b-col
          md="6"
          class="mb-1 mb-md-0"
        >
          <b-form-select
            v-model="searchQuery.active"
            :options="statusFilterOptions"
          />
        </b-col>

        <b-col
          md="6"
        >
          <project-type-select :type.sync="searchQuery.type" />
        </b-col>

        <b-col
          md="2"
          class="mt-1 mt-md-0"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-form-row>
    </b-card>

    <!-- Table -->
    <b-card>
      <b-table
        ref="table"
        :items="setFundraisingItems"
        :fields="tableColumns"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
        empty-text="No projects found"
        show-empty
      >
        <template #cell(startTime)="{item}">
          <span class="text-nowrap">{{ item.startTime }}</span>
        </template>

        <template #cell(endTime)="{item}">
          <span class="text-nowrap">{{ item.endTime }}</span>
        </template>

        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'fundraising-view', params: { id: item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50"> View </span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'fundraising-feedback-reports', params: { id: item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50"> Feedback Reports </span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'fundraising-edit', params: { id: item.id} }">
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>

            <template v-if="item.published !== 'completed' && $can('approve','FundraisingController') ">
              <b-dropdown-item @click="startProject(item.id)">
                <feather-icon icon="PlayIcon" />
                <span> Start </span>
              </b-dropdown-item>

              <b-dropdown-item @click="stopProject(item.id)">
                <feather-icon icon="StopCircleIcon" />
                <span> Stop </span>
              </b-dropdown-item>
            </template>

            <b-dropdown-item :to="{ name: 'fundraising-donations-list', params: { id: item.id} }">
              <feather-icon icon="DollarSignIcon" />
              <span> Donations </span>
            </b-dropdown-item>

            <b-dropdown-item @click="exportProject(item.id)">
              <feather-icon icon="DownloadIcon" />
              <span> Export </span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="(item.status === 2 && $can('publish','FundraisingController')) || item.status!==2"
              @click="confirmOperationFundraising(item)"
            >
              <feather-icon icon="TrashIcon" />
              <span
                class="align-middle ml-50"
              >Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <pagination
        :total-rows="pagination.totalRows"
        :per-page="pagination.perPage"
        :current-page.sync="pagination.currentPage"
      />
    </b-card>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import AddResource from '@/common/components/common/Table/AddResource.vue'
import deleteFundraising from '@/common/compositions/common/deleteRecord'
import ProjectStatus from '@/common/compositions/Fundraising/ProjectStatus'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import ProjectTypeSelect from '@/common/components/Fundraising/CrowdFundraising/MainInfo/ProjectTypeSelect.vue'
import Search from '@/common/components/common/Table/Search.vue'
import FundraisingApi from '@/common/compositions/Fundraising/FundraisingApi'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'CrowdFundList',
  components: {
    Pagination,
    AddResource,
    SearchButton,
    DateRangeFilter,
    ProjectTypeSelect,
    Search,
  },
  data() {
    return {
      deleteMessage: '',
      loading: true,
      searchQuery: {
        title: null,
        range: [],
        type: null,
        active: null,
      },
      tableColumns: [
        { key: 'title', formatter: v => v.slice(0, 25) },
        { key: 'toBeRaised', label: 'Target' },
        { key: 'startTime', label: 'start date time' },
        { key: 'endTime', label: 'end date time' },
        { key: 'status', formatter: v => this.statusOptions[v] },
        { key: 'zakatVerified', formatter: v => (v ? 'Yes' : 'No') },
        { key: 'feedbacks' },
        { key: 'published', formatter: value => value.charAt(0).toUpperCase() + value.slice(1) },
        { key: 'type', formatter: value => value.charAt(0).toUpperCase() + value.slice(1) },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/fundraising/`,
      statusFilterOptions: [
        { text: 'Both', value: null },
        { text: 'Active', value: true },
        { text: 'Inactive', value: false },
      ],
    }
  },
  computed: {
    statusOptions() {
      return ['Drafted', 'Send for approval', 'Approved']
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { deleteRecord, table } = deleteFundraising()
    const { startProject, stopProject } = ProjectStatus()
    const { getFundraisingProjects } = FundraisingApi()
    return {
      pagination,
      deleteRecord,
      table,
      startProject,
      stopProject,
      getFundraisingProjects,
    }
  },
  methods: {
    setFundraisingItems() {
      return this.getFundraisingProjects({
        title: this.searchQuery.title,
        page: this.pagination.currentPage,
        startTime: this.searchQuery.range[0],
        endTime: this.searchQuery.range[1],
        type: this.searchQuery.type,
        active: this.searchQuery.active,
        entityId: this.$store.getters['mainEntity/getEntityId'],
      }).then(res => {
        const projects = res.data.data

        this.pagination.totalRows = res.data.pagination.total
        return projects || []
      })
    },
    confirmOperationFundraising(item) {
      let message = ''

      if (item.published === 'published' && item.donorsCount) {
        message = 'Project is published and has donations , Do you want to delete?'
      } else if (item.published === 'published') {
        message = 'Project is published, Do you want to delete?'
      } else if (item.donorsCount) {
        message = 'Project has donations, Do you want to delete?'
      } else if (item.published === 'drafted') {
        message = 'Do you want to delete?'
      }

      this.deleteRecord(this.deleteEndPoint, item.id, message)
    },
    exportProject(id) {
      this.$activities.get(`/internalops/fundraising/project/${id}/export`, {
        responseType: 'blob',
      }).then(res => {
        const blob = new Blob([res.data])
        const filename = `${this.$moment().format('YYYY-MM-DD')}_fundraising.csv`
        saveAs(blob, filename)
      })
    },
    refreshTable() {
      this.$refs.table.refresh()
    },
  },
}
</script>
<style lang="scss">

</style>
